<template>
  <dx-util-popup
    ref="expenseCategoriesPopupRef"
    :drag-enabled="false"
    width="60vw"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="expenseCategoriesPopupShown"
    @hidden="expenseCategoriesPopupHidden"
  >
  <dx-util-scroll-view width="100%" height="100%">
    <div>
      <dx-util-validation-group>
        <div class="row px-2">
          <div class="col-2 px-half">
            <dx-util-text-box v-model="categoryForm.categoryName" label="Name">
              <dx-util-validator>
                <dx-util-required-rule message="Category name is required" />
              </dx-util-validator>
            </dx-util-text-box>
          </div>
          <div class="col-6 px-half">
            <dx-util-text-box v-model="categoryForm.description" label="Description" />
          </div>
          <div class="col px-half pt-half">
            <dx-util-button text="Add" class="d-block" type="success" @click="createExpense" />
          </div>
        </div>
      </dx-util-validation-group>
      <div id="expense-table" class="mt-2 px-2">
        <table width="100%">
          <tr class="text-warning">
            <th>
              Name
            </th>
            <th>
              Source
            </th>
            <th>
              Description
            </th>
            <th>
            </th>
          </tr>
          <tr v-for="item in expenseCategories" :key="item.id">
            <td>
              <category-updater :row="item" :field="'categoryName'" :editable="item.editable" @emit-update="getExpenseCategories" />
            </td>
            <td>
              <category-source-selector :row="item" :field-options="sourceOptions" :field="'sourceType'" :editable="item.editable" @emit-update="getExpenseCategories" />
            </td>
            <td>
              <category-updater :row="item" :field="'description'" :editable="item.editable" @emit-update="getExpenseCategories" />
            </td>
            <td>
              <dx-util-button :disabled="!item.editable" icon="remove" type="danger" styling-mode="text" @click="deleteExpenseCategory(item.id)" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import companyService from '@/http/requests/company/companyService'
import Pager from '@/@core/dev-extreme/utils/pager'
import { getExpenseSourceTypeList } from '@/enums/expenseSourceType.enum'
import IncomeExpenseSearchFilter from '@/http/models/search-filters/IncomeExpenseSearchFilter'
import { ExpenseSourceTypeEnum } from '@/enums'
import CategoryUpdater from './CategoryUpdater'
import CategorySourceSelector from './CategorySourceSelector'

export default {
  components: {
    'category-updater': CategoryUpdater,
    'category-source-selector': CategorySourceSelector,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'Expense',
    },
  },
  data() {
    return {
      popupTitle: '',
      categoryForm: {
        categoryType: this.type,
        categoryName: '',
        description: '',
        sourceType: ExpenseSourceTypeEnum.Manual.value,
      },
      sourceOptions: getExpenseSourceTypeList(),
      expenseCategories: [],
    }
  },
  computed: {
    expenseCategoriesPopup() {
      return this.$refs.expenseCategoriesPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.expenseCategoriesPopup.show()
        this.getExpenseCategories()
      },
    },
  },
  methods: {
    expenseCategoriesPopupShown() {
      this.popupTitle = `${this.type} Categories`
    },
    async deleteExpenseCategory(id) {
      this.$swal({
        title: 'Are you sure you want to delete this category?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          container: 'swal-container',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await companyService.deleteIncomeExpenseCategory(id)
          await this.getExpenseCategories()
        }
      })
    },
    expenseCategoriesPopupHidden() {
      this.clearForm()
    },
    clearForm() {
      this.categoryForm = {
        categoryType: this.type,
        categoryName: null,
        description: '',
        sourceType: ExpenseSourceTypeEnum.Manual.value,
      }
    },
    closePopup() {
      this.expenseCategoriesPopup.hide()
    },
    async createExpense(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        this.categoryForm.sourceType = ExpenseSourceTypeEnum.Manual.value
        await companyService.createIncomeExpenseCategory(this.categoryForm)
        await this.getExpenseCategories()
        this.clearForm()
        this.$emit('category-added')
      }
    },
    async getExpenseCategories() {
      const pager = new Pager({})
      IncomeExpenseSearchFilter.setDefaultFilters()
      IncomeExpenseSearchFilter.categoryType = this.type
      const filter = IncomeExpenseSearchFilter.getFilters()
      const response = await companyService.getIncomeExpenseCategoriesByQuery(pager.staticPageable, filter)
      this.expenseCategories = response.content
    },
  },
}
</script>

<style lang="scss">
#expense-table {
  th:last-child {
    width: 60px;
    text-align: left;
  }

  td:last-child {
    width: 60px;
    text-align: right;
  }

  th {
    width: 30%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 6px;
  }

  th:nth-child(2) {
    width: 15%;
  }

  th:nth-child(3) {
    width: 50%;
  }

  td {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 6px;
  }

  .dark-layout  tr:first-child {
    border-bottom: 1px solid rgb(99, 113, 148);
  }
  .dark-layout  tr {
    border-bottom: 0.5px solid rgb(99, 113, 148);
  }

  tr:first-child {
    border-bottom: 1px solid rgb(131, 182, 196, 0.5);
  }

  tr {
    border-bottom: 0.5px solid rgba(131, 182, 196, 0.5);
  }
}
.swal-container {
  z-index: 10000;
}
</style>
