<template>
  <div>
    <div v-if="editable">
      <div v-if="!isEditable">
        <span>
          {{ row[field] }}
        </span>
        <p-icon name="bi-pencil" color="warning" size="14px" role="button" @click.native="isEditable=true" />
      </div>
      <div v-else class="d-flex align-items-center">
        <input v-model="fieldValue" class="mr-half form-control">
        <p-icon name="bi-save" color="warning" size="14px" role="button" @click.native="update" />
      </div>
    </div>
    <div v-else>
      {{ row[field] }}
    </div>
  </div>
</template>

<script>
import companyService from '@/http/requests/company/companyService'

export default {
  props: {
    row: {
      type: Object,
      required: true,
      default: () => {},
    },
    field: {
      type: String,
      required: true,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isEditable: false,
      fieldValue: '',
    }
  },
  mounted() {
    this.fieldValue = this.row[this.field]
  },
  methods: {
    async update() {
      const payload = {
        id: this.row.id,
        [this.field]: this.fieldValue,
      }
      await companyService.upsertIncomeExpenseCategory(payload)
      this.$emit('emit-update')
      this.isEditable = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
